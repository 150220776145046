import {
  Routes,
  Route,
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import MyAppPage from './pages/MyAppPage';
import MyTesterPage from './pages/MyTesterPage';
import FindAppPage from './pages/FindAppPage';
import AppDetailsPage from './pages/AppDetailsPage';
import AppRegisterPage from './pages/AppRegisterPage';
import Main from './Main';
import RedirectPage from './pages/RedirectPage';
import AppTestPage from './pages/AppTestPage';

export function App() {
  // return (
  //   <div className="App">
  //     <BrowserRouter>
  //       <Routes>
  //         <Route path="/" element={<Main />} />
  //         <Route path="/login" element={<LoginPage />} />
  //         <Route path="/app/test" element={<AppTestPage />} />
  //         <Route
  //           path="/login/oauth2/authorization/google/redirect"
  //           element={<RedirectPage />}
  //         />
  //         <Route path="/myapp" element={<MyAppPage />} />
  //         <Route path="/mytester" element={<MyTesterPage />} />
  //         <Route path="/apps" element={<FindAppPage />}></Route>
  //         <Route path="/apps/:id" element={<AppDetailsPage />}></Route>
  //         <Route path="/register" element={<AppRegisterPage />}></Route>
  //         <Route path="/apps/:id/:pageNumber" element={<FindAppPage />}></Route>
  //       </Routes>
  //     </BrowserRouter>
  //   </div>
  // );

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Main />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/app/test',
      element: <AppTestPage />,
    },
    {
      path: '/oauth2/authorization/google/redirect',
      element: <RedirectPage />,
    },
    {
      path: '/myapp',
      element: <MyAppPage />,
    },
    {
      path: '/mytester',
      element: <MyTesterPage />,
    },
    {
      path: '/apps',
      element: <FindAppPage />,
    },
    {
      path: '/apps/:id',
      element: <AppDetailsPage />,
    },
    {
      path: '/register',
      element: <AppRegisterPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
