import React, { useState, useEffect } from 'react';
import MyAppLists  from '../src/components/MyAppLists';

function TextExample() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // MyAppLists 배열에서 첫 4개의 프로젝트만 가져오기
        setProjects(MyAppLists .slice(0, 4));
    }, []); // 컴포넌트 마운트 시에만 실행

    return (
        <div className="project">
            <h2>원하는 프로젝트를 찾아보세요</h2>
            <br />
            <div className="project-finder">
                <div className="row">
                    {projects.map((project, index) => (
                        <div className="col-sm-6" key={project.id} id={`card${index + 1}`}>
                            <div className="card">
                                <a className='custom-link' href="#">
                                    <div className="card-body">
                                        <h4 className="card-title">{project.title}</h4>
                                        <p className="card-text">{project.memberId}</p>
                                        <p className="card-text">{project.description}</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TextExample;