const MyAppLists = [
  {
    id: 1,
    memberId: 1,
    title: 'BlueDragonQuiz',
    description: '안드로이드 전용, 신조어관련',
    android_url: '',
    web_url: 'https://api.gplayus.com:8080/oauth2/google',
    // imageSrc: "",
    developer: 'AAA',
    joiner: 'Choi',
  },
  {
    id: 2,
    memberId: 2,
    title: 'Gplayus',
    description: '구글안드로이드 앱 등록 테스터 사용자를 위한 웹사이트',
    // imageSrc: "",
    android_url: 'string',
    web_url: 'string',
    developer: 'B',
    joiner: 'Park',
  },
  {
    id: 3,
    memberId: 3,
    title: '얌센김밥',
    description: '앱설명 10자 이상',
    // imageSrc: "",
    android_url: 'string',
    web_url: 'string',
    developer: 'C',
    joiner: '',
  },
  {
    id: 4,
    memberId: 4,
    title: '24시 전주콩나물국밥',
    description: '앱설명 10자 이상',
    // imageSrc: "",
    android_url: 'string',
    web_url: 'string',
    developer: 'C',
    joiner: 'kim',
  },
  {
    id: 5,
    memberId: 5,
    title: '순대실록',
    description: '앱설명 10자 이상',
    // imageSrc: "",
    android_url: 'string',
    web_url: 'string',
    developer: 'C',
    joiner: '',
  },
];

export default MyAppLists;
