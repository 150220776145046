import { jwtDecode } from "jwt-decode";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function RedirectPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const accessToken = searchParams.get("accessToken");
  console.log("accessToken");

  useEffect(() => {
    if (accessToken != null) {
      // accessToken decode 사용자정보
      const decoded = jwtDecode(accessToken);
      console.log(decoded);

      localStorage.clear();
      localStorage.setItem("accessToken", accessToken);
      navigate("/myapp");
    } else {
      navigate("/");
    }
  }, [accessToken, navigate]); // 종속성 배열에 accessToken과 navigate 추가
  console.log("accessToken");
  return <div>RedirectPage</div>;
}
