import React from 'react';
import './App.css'; // 스타일 파일을 import 합니다.
import logo1 from './images/logo.png';
import logo2 from './images/logo2.png';


function Footer() {
    return (
        <footer className="footer-container">
            <div className="footer-text">
                <h1> 안드로이드 APP 등록을 간편하게 </h1>
                <br />                <br/>

                누구든지 쉽게 참여가 가능합니다.
                <br />                <br/>

                아직 등록되지 않은 APP을 사용해 볼 수 있습니다.
                <br/>                <br/>

                구글 아이디만 있으면 참여, 테스터를 모을 수 있습니다.
            </div>
            <div className="logo-container">
                <div className="logo-item1">
                    <img src={logo1} alt="logo1" className="logo1" />
                    <div className="logo-title">안드로이드만 있으면</div>
                </div>
                <div className="logo-item">
                    <img src={logo2} alt="logo2" className="logo" />
                    <div className="logo-title">언제 어디서나</div>
                </div>

            </div>
         
        </footer>
    );
}

export default Footer;
