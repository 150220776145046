import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태 추가
  // 로그인 상태 변경 함수
  const handleLogin = () => {
    setIsLoggedIn(true);
    navigator('/login');
  };

  return (
    <Navbar bg="light" data-bs-theme="light" className="headerbar m-2">
      <Container>
        <Navbar.Brand href="#home">logo</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link as={Link} to="/register" className="nav-link">
            앱등록
          </Nav.Link>
          <Nav.Link as={Link} to="/apps" className="nav-link">
            앱 찾기
          </Nav.Link>
          <Nav.Link href="#pricing">이용방법</Nav.Link>
        </Nav>
        <Nav>
          {isLoggedIn && (
            <>
              <Link as={Link} to="/myapp" className="nav-link">
                앱등록
              </Link>
              <Link as={Link} to="/mytester" className="nav-link">
                참여중인 APP
              </Link>
              <Link as={Link} to="/logout" className="nav-link">
                로그아웃
              </Link>
            </>
          )}
          {!isLoggedIn && <Button onClick={handleLogin}>로그인</Button>}
        </Nav>
      </Container>
    </Navbar>
  );
}
