import React, { useState, useEffect } from 'react';
import CardComponent from './CardComponent';
import ImageComponent from './ImageComponent';
import './App.css';
import { Card } from 'react-bootstrap';

function InfiniteScroll() {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);

    // 예시 데이터
    const texts = [
        { title: 'TESTER 모집', description: '구글플레이 스토어 테스터 모집 중입니다.', image: '1.jpg' },
        { title: '손쉬운 APP 찾기', description: '손쉽게 APP을 찾아서 TESTER 혹은 등록할 수 있습니다.', image: '2.jpg' },
        { title: '구글 만으로 쉬운 가입', description: '구글 아이디만 있으면 간단하게 회원가입됩니다.', image: '3.jpg' },
        { title: '진행 상황 확인', description: '3단계의 진행상황을 확인합니다.', image: '4.jpg' }
    ];

    const handleScroll = () => {
        // 페이지의 스크롤 위치
        const scrollY = window.scrollY;
        // 텍스트의 인덱스 계산
        const index = Math.min(Math.floor(scrollY / window.innerHeight), texts.length - 1 );
        // 텍스트의 인덱스 설정
        setCurrentItemIndex(index);
    };

    // 페이지의 스크롤 이벤트 핸들러 등록
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="scrolling" style={{ height: `${texts.length * 80}vh`, overflowY: 'scroll' }}>
            {texts.map((item, index) => (
                <div className='scrolltext'  key={index} style={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'inline-block' }}>
                        <CardComponent title={item.title} description={item.description} />
                    </div>
                    {index === currentItemIndex && 
                        <div className='scrolimage' style={{ display: 'inline-block', marginLeft: '20px'}}>
                            <ImageComponent image={item.image} />
                        </div>
                    }
                </div>
            ))}
        </div>
    );
}

export default InfiniteScroll;
