import React from 'react';
import { useNavigate } from 'react-router-dom';


function Linetext() {
    let navigate = useNavigate();

    return (
        <div className="shadow bg-blue rounded-0 overflow-hidden bg-blue-no-padding" id='blueline'>
            <p className="text-white bg-info m-0 px-3 py-2 bg-info-no-padding bg-info-no-margin">누구보다 먼저 APP을 사용해보아요
            <button type="button" className="btn btn-primary" id="buttonone" onClick={() => navigate('/register')} id="appbutton">
                                APP TESTER 등록
                            </button>           
                             </p>
          
        </div>
    );
}

export default Linetext;