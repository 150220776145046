import React, { useEffect, useState } from 'react';
// import MyAppLists from './MyAppLists';
import AppCards from './AppCards';

export default function AppList({ status, searchData, clearSearch }) {
  const [fetchData, setFetchData] = useState([]);
  // console.log(`FindingAllApps appData ${fetchData}`);
  // console.log(fetchData);
  // console.log(status);

  //검색
  useEffect(() => {
    // console.log('AllApps effect');

    if (!searchData || searchData === '') {
    }
    //검색데이터 필터링
    else {
    }
  }, [searchData]);
  // console.log('dangdang');

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`https://api.gplayus.com/apps`);
  //       if (!response.ok) {
  //         throw new Error('데이터 가져오기 실패');
  //       }
  //       const data = await response.json();
  //       console.log(`AppList useEffect`);
  //       console.log(data);
  //       setFetchData(data);
  //     } catch (error) {
  //       console.error('Error 데이터 가져오기', error);
  //     }
  //   };

  //   fetchData();
  // }, [searchData]);

  return (
    <div>
      {searchData.content != null &&
        searchData.content.map((app) => (
          <AppCards
            app={app} // app prop 전달
            key={app.id}
            border={
              status === '대기'
                ? ' 2px solid black'
                : status === '진행'
                ? ' 2px solid red'
                : status === '완료'
                ? ' 2px solid blue'
                : ''
            }
          />
        ))}
    </div>
  );
}
