import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Button } from 'react-bootstrap'; // 추가된 부분
import { useNavigate } from 'react-router-dom';
import ImageAndTextExample from './ImageAndTextExample';
import ImageAndTextExampletwo from './ImageAndTextExampletwo';
import TextExample from './TextExample';
import Linetext from './Linetext';
import InfiniteScroll from './InfiniteScroll';
import Footer from './Footer';
import data from './data';
import './App.css';
import { Link } from 'react-router-dom';

function Main() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [isResponsive, setIsResponsive] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태 추가
  const navigator = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollPosition(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 768); // 768px 이하에서 반응형으로 처리
    };

    handleResize(); // 초기화
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const newIndex = Math.floor((scrollPosition - 1255) / 400);
    if (newIndex >= 0 && newIndex < data.length) {
      setCurrentContentIndex(newIndex);
    }
  }, [scrollPosition]);

  // 로그인 상태 변경 함수
  const handleLogin = () => {
    setIsLoggedIn(true);
    navigator('/login');
  };

  return (
    <div className="app-container">
      <Navbar bg="light" data-bs-theme="light" className="headerbar">
        <Container>
          <Navbar.Brand href="#home">logo</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/register" className="nav-link">
              앱등록
            </Nav.Link>
            <Nav.Link as={Link} to="/apps" className="nav-link">
              앱 찾기
            </Nav.Link>
            <Nav.Link href="#pricing">이용방법</Nav.Link>
          </Nav>
          <Nav>
            {/* 로그인 상태에 따라 버튼 렌더링 */}
            {isLoggedIn && (
              <>
                <Link as={Link} to="/myapp" className="nav-link">
                  앱등록
                </Link>
                <Link as={Link} to="/mytester" className="nav-link">
                  참여중인 APP
                </Link>
                <Link as={Link} to="/logout" className="nav-link">
                  로그아웃
                </Link>
              </>
            )}
            {!isLoggedIn && <Button onClick={handleLogin}>로그인</Button>}
          </Nav>
        </Container>
      </Navbar>

      <div>
        <ImageAndTextExample isResponsive={isResponsive} />

        <ImageAndTextExampletwo />

        <TextExample />

        <Linetext />

        <InfiniteScroll />
      </div>
      <Footer />
    </div>
  );
}

export default Main;
