import React, { useState, useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import images1 from './images/image1.jpg';
import './App.css';
import { useNavigate } from 'react-router-dom';


const texts = [
    {
        title1: '테스터 모집 진행 중',
        title2: '', // 두 번째 줄 제목 추가
    },
    {
        title1: '서로서로 도와 줍시다',
        title2: '', // 두 번째 줄 제목 추가
    },
];

function ImageAndTextExample() {
    let navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const [isResponsive, setIsResponsive] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % texts.length);
        }, 1000);

        return () => clearInterval(interval);

    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsResponsive(window.innerWidth <= 768); // 768px 이하에서 반응형으로 처리
        };

        handleResize(); // 초기화
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="documents">
            <div className="content">
                <div className="mainone">
                    <Card.Body className="cardone">
                        <Card.Title className="title">
                            <h1 className="titleone">{texts[index].title1}</h1>
                            {isResponsive && texts[index].title2 ? <div className="titletwo">{texts[index].title2}</div> : null}
                        </Card.Title>
                        <br /> <br />
                        <Card.Text className="textone">
                            <div>
                                APP TESTER <br /><br /><br />
                                안드로이드 APP을 서로서로 TEST
                                <br />
                                TESTER 로 참여하여 빠른 시간 내에 APP을 출시 합시다.
                                <br />
                                감사합니다.
                            </div>
                            <button type="button" className="btn btn-primary" id="buttonone" onClick={() => navigate('/register')}>
                                APP TESTER 등록
                            </button>
                        </Card.Text>
                    </Card.Body>
                </div>
                <div className='imagewrap'>
                    <Image src={images1} className="imagefirst" fluid />
                </div>
            </div>
        </div>
    );
}

export default ImageAndTextExample;
