import { React, useState, useEffect, useRef } from 'react';
import { Container, Navbar, Form, Row, Col, Button } from 'react-bootstrap';
import AppList from '../components/AppList';
import MyTesterStatus from '../components/Status/MyTesterStatus';
import Header from '../components/Header';
import Footer from '../Footer';

function MyTesterPage() {
  const [status, setStatus] = useState('진행');

  //검색
  const [searchInput, setSearchInput] = useState('');
  const search = useRef();
  const handleSearch = (e) => {
    e.preventDefault();
    //검색어 설정
    setSearchInput(search.current.value);
    console.log('검색 ' + search.current.value);
  };

  //입력된 검색어와 데이터 불일치
  const clearSearch = () => {
    console.log('clear search');
    setSearchInput('');
    search.current.value = '';
  };

  useEffect(() => {
    (async () => {
      const response = await fetch(
        'https://api.gplayus.com/members/testers/apps',
        {
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-type': 'application/json',
          },
        }
      );
      const data = await response.json();
      console.log(data);
    })();
  }, []);

  return (
    <div>
      <Container fluid>
        <div>
          <Header />
        </div>
        <Navbar className="bg-body-tertiary justify-content-between">
          <Form>
            <Row>
              <p className="fw-bold">
                {/* {memberName}님 MyTesterPage에 오신 걸 환영합니다. */}
                MyTesterPage에 오신 걸 환영합니다.
              </p>
            </Row>
          </Form>
          <Form>
            <Row>
              <Col>
                <Form.Control type="text" placeholder="Search" ref={search} />
              </Col>
              <Col xs="auto">
                {/* 앱검색 기능 */}
                <Button
                  type="submit"
                  value={searchInput}
                  onClick={handleSearch}
                >
                  찾기
                </Button>
              </Col>
            </Row>
          </Form>
        </Navbar>
        <Form>
          <Row>
            {/* 대기/진행/완료 <MyTesterStatus 컴퍼넌트 */}
            <Col>
              {<MyTesterStatus status={status} setStatus={setStatus} />}
            </Col>
          </Row>
        </Form>
      </Container>

      <hr />
      <div className="container-fluid">
        {/* 사용자 등록 앱 표시(카드형식) 진행/완료*/}
        {
          <AppList
            status={status}
            setStatus={setStatus}
            // memberemail={<AppCard {fetchData.content.app.name}/>}}
            searchData={searchInput}
            clearSearch={clearSearch}
          />
        }
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default MyTesterPage;
