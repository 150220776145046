import React, { useEffect, useState } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function AppCards({ app, border }) {
  // console.log('AppCard');
  const navigate = useNavigate();

  return (
    <div style={{ border: border }}>
      {app.memberResponse && (
        <Card
          style={{
            margin: '1rem',
            padding: '0.5rem',
            border: 'none',
            whiteSpace: 'pre-wrap',
          }}
          onClick={() => {
            //변경
            navigate(`/apps/${app.id}`);

            console.log('이동');
          }}
        >
          <Card.Body>
            <Card.Header as="h3">이름: {app.name}</Card.Header>
            <ListGroup>
              <ListGroup.Item>회원: {app.memberResponse.id}</ListGroup.Item>

              <ListGroup.Item>
                회원 이메일: {app.memberResponse.email}
              </ListGroup.Item>
              <ListGroup.Item>
                지원자: {app.memberResponse.testerResponse}
              </ListGroup.Item>
              <ListGroup.Item>앱 설명: {app.description}</ListGroup.Item>
              <Card.Link href="#">
                <ListGroup.Item>
                  웹(인터넷) 참여 신청: {app.webUrl}
                </ListGroup.Item>
                <ListGroup.Item>
                  앱(모바일) 참여 신청: {app.androidUrl}
                </ListGroup.Item>
              </Card.Link>
            </ListGroup>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
