import { useState } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function AppTestPage() {
  const onCreateApp = async () => {
    const response = await fetch('https://api.gplayus.com/apps', {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        name: '신조어 퀴즈',
        description: '신조어를 학습할 수 있는 앱 서비스',
        androidUrl: 'https://play.google.com',
        webUrl: 'https://play.google.com',
      }),
    });
    const data = await response.json();
    console.log(data);
  };

  const onSelectApps = async () => {
    const response = await fetch('https://api.gplayus.com/apps?page=2', {
      credentials: 'include',
    });
    const data = await response.json();
    console.log(data);
  };

  const onUpdateApp = async () => {
    const response = await fetch('https://api.gplayus.com/apps/501', {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        name: '신조어 퀴즈2',
        description: '신조어2를 학습할 수 있는 앱 서비스',
        androidUrl: 'https://play.google.com/2',
        webUrl: 'https://play.google.com/2',
      }),
    });
    const data = await response.json();
    console.log(data);
  };

  const onDeleteApp = async () => {
    const response = await fetch('https://api.gplayus.com/apps/501', {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-type': 'application/json',
      },
    });
    const data = await response.json();
    console.log(data);
  };
  const onSelectApp = async () => {
    const response = await fetch('https://api.gplayus.com/apps/3', {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-type': 'application/json',
      },
    });
    const data = await response.json();
    console.log(data);
  };
  const onApplyTester = async () => {
    const response = await fetch('https://api.gplayus.com/apps/3/members', {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-type': 'application/json',
      },
    });
    const data = await response.json();
    console.log(data);
  };
  const onCreateTester = async () => {
    const response = await fetch('https://api.gplayus.com/apps/3/testers/501', {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-type': 'application/json',
      },
    });
    const data = await response.json();
    console.log(data);
  };

  const onSelectMemberApps = async () => {
    const response = await fetch('https://api.gplayus.com/members/apps', {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-type': 'application/json',
      },
    });
    const data = await response.json();
    console.log(data);
  };

  const onSelectTesterApps = async () => {
    const response = await fetch(
      'https://api.gplayus.com/members/testers/apps',
      {
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-type': 'application/json',
        },
      }
    );
    const data = await response.json();
    console.log(data);
  };

  return (
    <div>
      <button onClick={onCreateApp}>앱 등록</button>
      <br />
      <button onClick={onSelectApps}>앱 조회</button>
      <br />
      <button onClick={onUpdateApp}>앱 수정</button>
      <br />
      <button onClick={onDeleteApp}>앱 삭제</button>
      <br />
      <button onClick={onSelectApp}>앱 상세정보 조회</button>
      <br />
      <button onClick={onApplyTester}>앱 테스터 신청</button>
      <br />
      <button onClick={onCreateTester}>앱 테스터 등록</button>
      <br />
      <button onClick={onSelectMemberApps}>회원이 등록한 앱 조회</button>
      <br />
      <button onClick={onSelectTesterApps}>
        회원이 테스터로 지원한 앱 조회
      </button>
      <br />
    </div>
  );
}

export default AppTestPage;
