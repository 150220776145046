import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import images1 from './images/image2.png';

function ImageAndTextExampletwo() {
    return (
        <div className="documentstwo">
            <div className="contenttwo">
              <div className="twoimagewrap">
                        <Image src={images1} className="twoimagefirst" fluid />
                    </div>
            <Row>
                
                  
                
                <Col lg={4}>
                    <Card className="twomainone">
                        <Card.Body className="twocardone">
                            <Card.Text className="twotextone">
                                <div>여기에는 <br />설명하는 내용을 넣는다</div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className="twomaintwo">
                        <Card.Body className="twocardone">
                            <Card.Text className="twotextone">
                                <div>여기에는 <br />설명하는 내용을 넣는다</div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className="twomainthree">
                        <Card.Body className="twocardone">
                            <Card.Text className="twotextone">
                                <div>여기에는 <br />설명하는 내용을 넣는다</div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </div>
        </div>
    );
}

export default ImageAndTextExampletwo;
