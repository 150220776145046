import { React, useEffect } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import logo1 from '../assets/logo1.png';
import Glogo from '../assets/Glogo.svg';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const navigate = useNavigate();
  const redirectHome = () => {
    navigate(`/`);
  };

  //구글 로그인
  const requestBackReaponse = async () => {
    window.location.href =
      'https://api.gplayus.com/oauth2/authorization/google';
    // window.location.href =
    //   "https://api.gplayus.com:3000/oauth2/authorization/google";
  };

  return (
    <Container
      fluid
      className="vh-100 d-flex justify-content-center align-items-center"
    >
      <div className="p-1 d-flex justify-content-center align-items-center">
        <div className="shadow p-1 mb-5 bg-body-tertiary rounded">
          <img
            src={logo1}
            alt="Gplayus_logo"
            style={{ width: '100%', maxWidth: '300px', height: 'auto' }}
            onClick={redirectHome}
          />
          <Container className="align-items-center shadow p-1 mb-0 bg-body-tertiary rounded">
            <Form>
              <Row className="align-items-center gx-1">
                <Col xs="auto">
                  <img src={Glogo} alt="Glogo" />
                </Col>
                <Col className="d-grid ">
                  <Button
                    variant="primary fw-bold"
                    onClick={requestBackReaponse}
                  >
                    구글 로그인
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </div>
    </Container>
  );
}

export default LoginPage;
