import React from 'react';

function ImageComponent({ image }) {
    return (
        <div className="image">
            <img src={require(`./images/${image}`)} alt="Slide" />
        </div>
    );
}

export default ImageComponent;